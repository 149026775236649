@font-face {
  font-family: "Elice Digital Baeum", sans-serif;
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/EliceDigitalBaeum_Regular.ttf"),
    url("./fonts/EliceDigitalBaeumOTF_Regular.otf");
}

@font-face {
  font-family: "Elice Digital Baeum", sans-serif;
  font-weight: 700;
  font-style: bold;
  src: url("./fonts/EliceDigitalBaeum_Bold.ttf"),
    url("./fonts/EliceDigitalBaeumOTF_Bold.otf");
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

body {
  font-family: "Elice Digital Baeum", sans-serif !important;
  background-color: #181526 !important;
  padding-bottom: 50px;
  color: white !important;
}

html {
  scroll-behavior: smooth;
}

.btn-primary {
  background-color: #05baad !important;
  border: none !important;
}

#breed {
  max-width: 1000px !important;
}

#breed .modal-content {
  background-color: #141416;
  border-radius: 16px;
}

.primary-text {
  color: #05baad !important;
}

.adopt-cta {
  color: white !important;
}

.adopt-cta:hover {
  text-decoration: none;
  color: white !important;
}

.primary-text:hover {
  color: #05baad !important;
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}

#breed-button:hover {
  background-color: #34ebdf !important;
  text-decoration: none;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.header {
  text-align: center;
}

.section-top {
  margin-top: 150px;
}

.custom-btn-toolbar {
  display: inline !important;
}

.custom-btn-toolbar > .btn {
  margin-right: 1rem;
}

.global-nav {
  padding: 30px 20px !important;
  margin: 0 auto !important;
  max-width: 1500px;
}

.dropdown-menu {
  min-width: 100px !important;
}

.nav-logo {
  width: 64.76px;
  height: 80px;
}

.nav-item {
  color: white !important;
  font-size: 1 rem;
  align-self: center;
  margin: 0 20px;
}

#basic-nav-dropdown {
  color: white !important;
  font-size: 1 rem;
  align-self: center;
  margin: 0;
  background-color: #05baad;
  padding: 10px 16px;
  border-radius: 4px;
}

.refresh {
  color: #05baad !important;
  font-size: 18px;
  font-weight: 700;
  width: 150px;
  word-break: break-all;
}

.catchphrase {
  padding: 20px;
  display: flex;
  justify-content: center;
  margin: 40px auto 40px;
  color: white !important;
  font-size: 46px;
  font-weight: 700;
  line-height: 58px;
}

.hero-image-container {
  display: flex;
  justify-content: center;
}

.hero-image {
  width: 80%;
  max-height: 460px;
}

.mint-button-container {
  display: flex;
  justify-content: center;
}

.mint-button {
  border: none;
  text-align: center;
  background-color: #05baad;
  margin-top: 40px;
  width: 196px;
  height: 52px;
  color: white !important;
  font-size: 18px;
  font-weight: 700;
  border-radius: 4px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  display:flex;
  align-items:center;
  justify-content:center;
}

.mint-button:hover {
  background-color: #60f2e8;
  color: black;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1000;
}

.learn-more-button {
  border: #05baad 2.5px solid;
  text-align: center;
  margin-left: 30px;
  background-color: transparent;
  margin-top: 40px;
  width: 196px;
  height: 52px;
  color: #05baad;
  font-size: 18px;
  font-weight: 700;
  border-radius: 4px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  display:flex;
  align-items:center;
  justify-content:center;
}

.learn-more-button:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1000;
}

.modal-title {
  color: black !important;
}

.modal-body {
  color: black !important;
}

.mint-count-title {
  margin-top: 80px;
  text-align: center;
  color: white !important;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}

.mint-button-description {
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  color: white !important;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
}

.minting-info {
  text-align: center;
  border: white 2px solid;
  padding: 30px;
  width: fit-content;
  color: white !important;
  margin: 60px auto 20px auto;
  border-radius: 6px;
}

.paragraph {
  color: white !important;
  font-size: 1 rem;
  align-self: center;
  margin: 0;
}

.login-button {
  background-color: #05baad !important;
  border: none !important;
  width: 160px;
  height: 50px;
  padding: 10px 15px;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  font-size: 15px;
}

.login-button:hover {
  background-color: #60f2e8 !important;
  color: black;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1000;
}

.section {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
  max-width: 80%;
  height: 600px;
  margin: auto;
  max-width: 1800px;
}

.section-text {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.section-title {
  white-space: pre-line;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
}

.section-description {
  margin-top: 50px;
  width: 96%;
  white-space: pre-line;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

.section-bullet-container {
  margin-top: 40px;
}

.section-bullet {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 90%;
  white-space: pre-line;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

.section-image {
  width: 80%;
  max-width: 320px;
  max-height: 320px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.intro-container {
  margin-top: 150px;
  margin-bottom: 80px;
}

.intro-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: 36px;
  line-height: 38px;
  text-align: center;
  font-weight: 700;
}

.intro-description {
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: white;
  margin-top: 40px;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 32px;
  text-align: center;
  font-weight: 400;
  white-space: pre-line;
}

.dinos-ground {
  width: 100%;
  margin-top: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@keyframes ani {
  100% {
    background-position: 0 0;
  }
  0% {
    background-position: 10000000px 0;
  }
}

.dinos {
  /* display: block; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  background: url("./assets/images/dinos.png") repeat-x;
  height: 118px; /* this value is not important, just not zero */
  animation: ani 100000s linear infinite;
  vertical-align: middle;
}

.dinos-ground {
  overflow: hidden;
  margin-top: 100px;
}

.ground {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.end-mint {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-weight: 700;
}

.moving-dinos {
  margin-left: -60px;
  max-height: 110px;
  overflow: hidden;
  clip: rect(0px, 50px, 100px, 0px);
}

.adopt-mint-button {
  border: none;
  background-color: #05baad;
  margin-top: 100px;
  max-width: 1500px;
  width: 100%;
  height: 110px;
  color: white;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  justify-content: space-between;
  margin-top: 180px;
  text-align: center;
}

.adopt-text {
  width: 100%;
  color: white;
  font-size: 24px;
  text-align: center;
}

.adopt-mint-button:hover {
  background-color: #60f2e8;
  color: black;
  text-decoration: none;
}

.adopt-mint-text {
  display: flex;
  vertical-align: middle;
  height: 50;
  font-weight: 700;
  font-size: 36px;
  margin-left: 40px;
  align-items: center;
}

.mint-egg {
  margin-left: 20px;
  margin-right: 20px;
}

.need-kaikas {
  font-weight: 700;
  text-decoration: none;
  color: #05baad;
}

.need-kaikas:hover {
  font-weight: 700;
  text-decoration: none;
  color: #60f2e8;
}

.adopt-arrow-right {
  margin-right: 50;
}

.max-width-1500 {
  max-width: 1500px;
}

.collection-title-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
}

.dino-image {
  cursor: pointer;
  width: 80%;
  border-radius: 4px;
  max-width: 450px;
  display: block;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  z-index: 0;
  /* margin-left: auto;
  margin-right: auto; */
}

.dino-image:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1000;
}

.collection-title {
  margin-bottom: 80px;
}

.collection-title-first {
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  color: white;
}

.collection-title-second {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: white;
}

/* .spec {
  display: inline-block;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
  max-width: 80%;
  height: 600px;
  margin: auto;
  max-width: 1800px;
} */

.spec {
  margin-top: 200px;
  display: block;
  /* margin-left: auto; */
  margin-right: auto;
  width: 80%;
}

.specs {
  color: #05baad;
  font-weight: 700;
  font-size: 16px;
}

.spec-title {
  color: white !important;
  white-space: pre-line;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  font-weight: 700;
}

.spec-divider {
  height: 2px;
  border-width: 0;
  color: white;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-start;
}

.spec-subtitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.spec-nickname {
  color: #05baad;
  font-weight: 700;
  font-size: 24px;
}

.spec-count {
  color: white;
  font-weight: 700;
  font-size: 24px;
}

.spec-description {
  color: white !important;
  margin: 30px 0;
  line-height: 32px;
  font-size: 18px;
  height: 180px;
}

.spec-collection-arrows {
  display: flex;
}

.collection-arrow-left {
  cursor: pointer;
  width: 48px;
  height: 48px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  z-index: 0;
}

.collection-arrow-left:hover {
  cursor: pointer;
  width: 48px;
  height: 48px;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1000;
}

.collection-arrow-right {
  cursor: pointer;
  width: 48px;
  height: 48px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  z-index: 0;
}

.collection-arrow-right:hover {
  cursor: pointer;
  width: 48px;
  height: 48px;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1000;
}

.roadmap {
  max-width: 1500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.roadmap-title {
  color: white !important;
  white-space: pre-line;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 50px;
}

.roadmap-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 40px;
}

.roadmap-phase {
  color: #05baad;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  width: 150px;
}

.roadmap-item {
  max-width: 450px;
}

.roadmap-item-title {
  color: white;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.roadmap-item-description {
  white-space: pre-line;
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.roadmap-image-container {
  margin-top: 150px;
}

.roadmap-image {
  width: 80%;
  max-width: 412px;
  max-height: 590px;
  display: block;
  margin: auto;
}

.navbar-light .navbar-nav .nav-link {
  color: white !important;
}

.teams-title {
  text-align: center;
  color: white;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
}

.teams-subtitle {
  margin-top: 25px;
  text-align: center;
  color: white;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
}

.teams {
  max-width: 1500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.profiles {
  margin-top: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.profile-photo {
  max-width: 384px;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

.profile-photo:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1000;
}

.profile-item {
  max-width: 384px;
  width: 80%;
  margin-top: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.profile-item-title {
  color: #05baad;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.profile-item-role {
  color: white;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.profile-item-description {
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.footer {
  max-width: 1500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
}

.footer-divider {
  height: 2px;
  border-width: 0;
  color: white;
  background-color: white;
  margin-top: 30px;
  margin-bottom: 40px;
  justify-content: flex-start;
}

.footer-items {
  display: flex;
  justify-content: space-between;
}

.footer-email-title {
  color: white;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.footer-email {
  color: #05baad;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.footer-email:hover {
  color: #60f2e8;
  text-decoration: none;
}

.social-container {
  display: flex;
  margin-top: 50px;
  max-width: 1500px;
  justify-content: center;
  flex-wrap: wrap;
}

.social-button {
  text-decoration: none;
  cursor: pointer;
  width: 196px;
  height: 64px;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  border: white 2px solid;
  border-radius: 4px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 30px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

.social-button:hover {
  background-color: #05baad;
  border: none;
  text-decoration: none;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1000;
}

.social-button-icon {
  width: 36px;
  height: 36px;
  margin-left: 10px;
  margin-right: 15px;
}

.social-button-text {
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.social {
  display: flex;
  margin-left: auto;
}

.social-icon {
  margin-right: 25px;
}

.social-image {
  width: 24px;
  height: 24px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

.social-image:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 1000;
}

/* .mint-cancel {
  height: 50px;
  width: 300px;
  background-color: #dcdcdc;
  border: none;
} */

.mint-cancel {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 10px;
}


.mint-info {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}

.mint-cancel:hover {
  background-color: #a9a9a9;
}

@media (max-width: 1200px) {
  .roadmap-container {
    display: inline-block;
  }
}

@media (max-width: 1000px) {
  .catchphrase {
    font-size: 42px;
  }

  .mint-button {
    width: 150px;
    font-size: 16px;
  }

  .learn-more-button {
    width: 150px;
    margin-left: 25px;
    font-size: 16px;
  }

  .login-button {
    width: 140px;
    font-size: 14px;
  }

  .dino-image {
    width: 90%;
    margin: auto;
  }

  .roadmap-image {
    width: 90%;
    max-width: 412px;
    max-height: 590px;
  }

  .adopt-mint-text {
    font-size: 24px;
  }

  .spec {
    margin-top: 50px;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .spec-collection-arrows {
    margin-left: 20px;
  }

  .intro-description {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
  }
}

@media (max-width: 500px) {
  .global-nav {
    padding: 20px 5px !important;
    margin: 0 auto 0 15px !important;
  }

  .nav-item {
    margin: 0 15px;
  }

  .nav-logo {
    width: 40px;
    height: 50px;
  }

  .hero-image {
    width: 95%;
  }

  .section-top {
    margin-top: 100px;
  }

  .dinos {
    width: 95%;
  }

  .catchphrase {
    font-size: 30px;
  }

  .collection-title-container {
    padding-right: 20px;
  }

  .collection-title-first {
    font-size: 24px;
  }

  .collection-title-second {
    font-size: 24px;
  }

  .collection-title {
    margin-bottom: 50px;
  }

  .spec-nickname {
    font-size: 16px;
    width: 60%;
  }

  .spec-count {
    font-size: 16px;
  }

  .spec-description {
    font-size: 16px;
    margin: 20px auto 50px auto;
    height: 250px;
  }

  .adopt-mint-button {
    height: 80px;
    margin-top: 100px;
  }

  .mint-egg {
    width: 40px;
    height: 40px;
  }

  .adopt-mint-text {
    font-size: 18px;
    margin-left: 20px;
  }

  .mint-egg {
    margin-left: 15px;
  }

  .roadmap-image-container {
    margin-top: 80px;
  }

  .footer-items {
    display: inline-block;
    justify-content: space-between;
  }

  .social {
    margin-top: 50px;
  }

  .footer {
    padding-bottom: 100px;
  }
}

@media (max-width: 340px) {
  .nav-item {
    margin: 0 10px;
  }
}
